/*
 * @Descripttion: *
 * @version: *
 * @Author: Fengshi
 * @Date: 2020-11-22 17:52:37
 * @LastEditors: Fengshi
 * @LastEditTime: 2020-11-22 17:54:25
 */
import account from './account.vue'
export default account
