/*
 * @Descripttion: *
 * @version: *
 * @Author: Fengshi
 * @Date: 2020-11-22 17:52:28
 * @LastEditors: Fengshi
 * @LastEditTime: 2020-11-23 21:25:58
 */
import IconTitle from '../../../Overview/components/common/iconTitle'
export default {
  name: 'account',
  components: {
    IconTitle
  },
  data () {
    return {
      amount: 0
    }
  },
  methods: {
    async init () {
      const data = await this.$wPost('/admin/finance/get.do')
      this.amount = data
    }
  },
  created () {
    this.init()
  }
}
